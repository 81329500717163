<template>
  <v-footer dark min-height="60">
    <span class="caption">Epic Receptionists, LLC</span>
    <a class="ml-2 d-none d-sm-flex" href="http://instagram.com/epic.receptionists" target="_blank" rel="noopener">
      <span class="d-sr-only">Instagram</span>
      <v-icon small>{{ iconInstagram }}</v-icon>
    </a>
    <a class="ml-2 d-none d-sm-flex" href="https://www.facebook.com/EpicReceptionists" target="_blank" rel="noopener">
      <span class="d-sr-only">Facebook</span>
      <v-icon small>{{ iconFb }}</v-icon>
    </a>
    <a
      class="ml-2 d-none d-sm-flex"
      href="https://www.linkedin.com/company/epic-receptionists"
      target="_blank"
      rel="noopener"
    >
      <span class="d-sr-only">LinedIn</span>
      <v-icon small>{{ iconLinkedIn }}</v-icon>
    </a>
    <v-spacer class="d-none d-sm-flex" />
    <span class="caption d-none d-sm-flex">
      <!-- Made with <v-icon color="red" small>{{ iconHeart }}</v-icon> Epic Receptionists Is A Black Owned Company -->
      Made in Michigan
      <v-img class="d-inline-block ml-n1 mr-3" width="20" height="20" contain :src="iconMichigan" alt="The Mitten" />
      <span class="d-none d-md-inline align-center">Epic Receptionists Is</span>
      <span class="d-md-none d-sm-inline-block">We Are</span>
      A Black Owned Company
    </span>
    <v-divider vertical class="mx-3" />
    <div class="d-flex justify-space-around align-stretch">
      <span class="caption">
        <nuxt-link class="white--text" :to="{ name: 'privacy' }"> Privacy Policy</nuxt-link>
      </span>
      <v-divider vertical class="mx-3" />
      <span class="caption">
        <nuxt-link class="white--text" :to="{ name: 'terms-of-service' }">ToS</nuxt-link>
      </span>
    </div>
  </v-footer>
</template>

<script>
import { mdiHeart, mdiInstagram, mdiFacebook, mdiLinkedin } from '@mdi/js'
import iconMichigan from '@/assets/icon-michigan.svg'

export default {
  name: 'HomeFooter',

  data: () => ({
    iconMichigan,
    iconHeart: mdiHeart,
    iconInstagram: mdiInstagram,
    iconFb: mdiFacebook,
    iconLinkedIn: mdiLinkedin
  })
}
</script>
